.mainContainer {
	display: flex;
	justify-content: center;
}

.contentContainer {
	overflow: hidden;
	width: 100vw;
	max-width: 1920px;
}
