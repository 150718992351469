/* Conteneur spécifique pour SearchForm */
.search-form-container {
    display: flex;
    justify-content: flex-start; /* Aligner SearchForm à gauche */
    align-items: flex-start; /* Aligner les éléments à leur position d'origine */
    width: 100%;
    max-width: 1200px; /* Vous pouvez ajuster cela en fonction de la largeur souhaitée */
    margin: 0 auto; /* Centrer le SearchForm dans le conteneur, mais sans centrer à l'intérieur */
}

/* Applique une taille plus grande uniquement à SearchForm */
.search-form-container .icon {
    width: 5rem;  /* Taille plus grande pour les icônes */
    height: auto;
}

/* Agrandir les boutons dans SearchForm */
.search-form-container .w-32, .search-form-container .h-32 {
    width: 9rem;  /* Taille des boutons augmentée */
    height: 9rem;
}

/* Maintenir l'effet de survol pour le changement de taille */
.search-form-container .transition-transform {
    transition: transform 0.3s ease-in-out;
}

.search-form-container .hover\\:scale-125:hover {
    transform: scale(1.25);
}
