.avatar-upload {
    width: 80px;
    height: 80px;
  }
  
  .avatar-preview {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .edit-icon {
    position: absolute;
    top: 34%;
    left: 34%;
    font-size: 15px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1; /* Assurez-vous que l'icône est au-dessus de l'image */
    display: none; /* Caché par défaut */
}

/* Conteneur pour l'image de profil */
.avatar-preview {
    position: relative;
    display: inline-block; /* Assurez-vous que le conteneur prend la taille de l'image */
}

/* Afficher l'icône de modification au survol */
.avatar-preview:hover .edit-icon {
    display: block;
}
  