/* /* ==================== REUSABLE CSS CLASSES ====================
.container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 3rem 0;
    min-height: 100vh;
    display: grid;
    place-items: center;
}

/*==================== SERVICE CARD ====================*/
.serv_card__container {
    display: flex;
    flex-wrap: wrap;
    margin: auto !important;
    justify-content: center;
    margin: auto;
    gap: 20px;
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
}

.card__bx.card__1.col-md-4 {
    width: 30%;
}

@media only screen and (max-width:768px) {
    .card__bx.card__1.col-md-4 {
        width: 100%;
        margin-top: 10px;
    }
}



.card__bx {
    --bg-clr: #000710;
    --txt-clr: #ffffff;
    --btn-txt-clr: #333333;
    --transition: all 0.5s;


    height: 474px;

    overflow: hidden;
    border-radius: 20px;
    border-top-left-radius: 70px;
    position: relative;
    overflow: hidden;
    background: var(--clr);
    transition: var(--transition);
}

.card__1 {
    --clr: #3b82f6;
}

.card__2 {
    --clr: #3b82f6;
}

.card__3 {
    --clr: #3b82f6;
}

.card__bx .card__data {
    position: absolute;
    inset: 10px;
    border-radius: 10px;
    background: var(--bg-clr);
}

.card__bx .card__data .card__icon {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 50%;
    height: 140px;
    width: 140px;
    background: var(--clr);
}

.card__bx .card__data .card__icon::before {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    height: 30px;
    width: 30px;
    border-top-left-radius: 30px;
    background: transparent;
    box-shadow: -5px -5px 0 5px var(--clr);
}

.card__bx .card__data .card__icon::after {
    content: '';
    position: absolute;
    right: -30px;
    top: 0;
    height: 30px;
    width: 30px;
    border-top-left-radius: 30px;
    background: transparent;
    box-shadow: -5px -5px 0 5px var(--clr);
}

.card__bx .card__data .card__icon .card__icon-bx {
    position: absolute;
    inset: 10px;
    border-radius: 10px;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background: var(--bg-clr);
    color: var(--clr);
}

.card__bx:hover .card__data .card__icon .card__icon-bx {
    background: var(--clr);
    color: var(--txt-clr);
    transition: var(--transition);
}

.card__bx .card__data .card__content {
    position: absolute;
    top: 150px;
    padding: 20px;
    text-align: center;

    justify-content: center;
    flex-direction: column;
    gap: 15px;
    color: var(--txt-clr);
}

.card__bx .card__data .card__content h3 {
    font-size: 24px;
    font-weight: 400;
    text-transform: capitalize;
}

.card__bx .card__data .card__content p {
    font-size: 17px;
    opacity: 0.75;
    font-weight: 300;
}

.card__bx .card__data .card__content a {
    display: inline-flex;
    align-self: center;
    padding: 10px 25px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    border: 2px solid var(--clr);
    color: var(--btn-txt-clr);
    background: var(--clr);
    transition: var(--transition);
}

.card__bx .card__data .card__content a:hover {
    background: transparent;
    color: var(--clr);
}